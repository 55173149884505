import React from "react";
import AppBarInnovanex from "../components/Landing/AppBar";
import Abstract from "../components/Landing/Abstract";
import Destacados from "../components/Landing/Destacados";
import Cooperators from "../components/Landing/Cooperators";
import About from "../components/Landing/About";
import Objectives from "../components/Landing/Objectives";
import ProjectChart from "../components/Landing/ProjectChart";
import InfoContratacion from "../components/Landing/InfoContratacion";
import InvitacionPrivada from "../components/Landing/InvitacionPrivada";
import Footer from "../components/Landing/Footer";
import Contact from "../components/Landing/Contact";
import BackToTop from "../components/BackToTop";
import Cover from "../components/Landing/Cover";
import Layout from "../Layout";
import FeedInstagram from "../components/Landing/FeedInstagram"
import Sites from "../components/Landing/Sites";
import CircleNavigation from "../components/Landing/CircleNavigation";
import InfoConvocatorias from "../components/Landing/InfoConvocatorias";
import IDataQbo from "../components/Landing/IDataqbo";

export default function Landing(props) {
  return (
    <Layout>
      <BackToTop props={props} />
      <div>
        <AppBarInnovanex />
      </div>
      <div>
        <Cover />
      </div>
      <div>
        <Destacados/>
      </div>
      {/* ----------------------- PROYECTO  ---------------------- */}
      {/* Resumen del proyecto */}
      <div id="abstract">  
        <Abstract />
      </div>
      {/* Componentes */}
      <div id="objectives">
        <Objectives />
      </div>
      {/* Avance del proyecto */}
      <div id="projectchart">
        <ProjectChart/>
      </div>
      {/* Sitios turisticos */}
      <div id="sites">
        <Sites />
      </div>
      {/* Linea del tiempo del proyecto */}
      <div id="timeline">
        <CircleNavigation/>
      </div>
      {/* ------------------------  CONVOCATORIAS Y CONTRATACIÓN------------------------- */}
      <div id="infoconvocatorias">
        <InfoConvocatorias />
      </div>
      <div id="infocontratacion">
        <InfoContratacion/>
      </div>
      <div id="Terminosdereferencia">
          <InvitacionPrivada/>
         </div>
      {/* <div id="talleresidataqbo">
        <IDataQbo/>
      </div> */}
      {/* ----------------------------  SOBRE NOSOTROS  ------------------------- */}
      {/* <div id="feedinstagram">
        <FeedInstagram/>
      </div> */}
      <div id="about">
        <About />
      </div>
      
      <div id="allies">
        <Cooperators />
      </div>
      
      
      {/* <div>
        <TimeLine />
      </div> */}
      <div id="contact">
        <Contact />
      </div>
      <div>
        <Footer />
      </div>
    </Layout>
  );
}
