import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles((theme) => ({
  image: {
    width: "50%",
    height: "auto",
  },
  imageXS: {
    width: "55%",
    height: "auto",
  },
  imageSM: {
    width: "25%",
    height: "auto",
  },
  grow: {
    flexGrow: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: "#000",
    textTransform: "uppercase",
    // "&:hover": {
    //   color: theme.palette.color4.main,
    //   // textDecoration: "none",
    // },
  },
  logo: {
    width: "30%",
  },
  logoXS: {
    width: "100%",
  },
  logoSM: {
    width: "100%",
  },
  logoMD: {
    width: "30%",
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menu: {
    color: "#000",
  },
}));

export default function AppBarInnovanex(props) {
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { window } = props;
  const XS = useMediaQuery("(max-width:600px)");
  const SM = useMediaQuery("(max-width:960px)");

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 600,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <nav>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            target="_blank"
            href="https://contratacion.innovanex.com.co/auth/login"
          >Presentarse a convocatoria 
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            target="_blank"
            href="#infoconvocatorias"
          >Información de convocatorias vigentes
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            target="_blank"
            href="#infoconvocatorias"
          >Información contractual
          </Link>
        </MenuItem>
       {/* <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            target="_blank"
            href="#Términos de referencia"
          >Términos de referencia
          </Link>
        </MenuItem>*/}

        <MenuItem>
         <Link
          className={classes.link}
          variant="button"
          color="textPrimary"
          target="_blank"
          href="#Terminosdereferencia"
          >Términos de referencia
         </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            target="_blank"
            href={"contratacion/Política_tratamiento_de_datos_UT_Innovanex.pdf"}
          >Politica de tratamiento de datos 
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            href={"contratacion/Manual_de_Contratación_V4_FinalAprobad.pdf"}
            target="_blank" 
          >Manual de contratación
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            target="_blank"
            href="https://innovanex.s3.us-east-2.amazonaws.com/DATOS_SERVER/Anexo+N%C2%BA+1+Manual+de+Contrataci%C3%B3n_V4+_FinalAprobad+(2).pdf"
          >
            Contratación 
          </Link>
        </MenuItem> */}
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            href="#about"
          >
            Sobre nosotros
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            href="#objectives"
          >
            Componentes
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            href="#allies"
          >
            Aliados
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            href="#sites"
          >
            Beneficiados
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={classes.link}
            variant="button"
            color="textPrimary"
            href="#contact"
          >
            Contacto
          </Link>
        </MenuItem>
      </nav>
    </Menu>
  );


  return (
    <div className={classes.grow} in={trigger.toString()}>
      <AppBar
        elevation={3}
        style={{ background:  "#fff"  }}
      >
        <Toolbar>
          <div
            className={XS ? classes.logoXS : SM ? classes.logoSM : classes.logo}
          >
            <img
              className={
                XS ? classes.imageXS : SM ? classes.imageSM : classes.image
              }
              src={"LogoInnovanex.png"}
              alt='logo innovanex'
            ></img>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Menu desplegable"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon className={classes.menu} />
            </IconButton>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <nav>
            <Button className={classes.link} variant="outlined" aria-haspopup="true" onClick={handleClick}>
              Contratación y convocatorias
            </Button>
            <Menu
              variant="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  variant="button"
                  color="textPrimary"
                  target="_blank"
                  href="https://contratacion.innovanex.com.co/auth/login"
                >Presentarse a convocatoria
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
              <Link
                  className={classes.link}
                  variant="button"
                  color="textPrimary"
                  href="#infoconvocatorias"
              >Información de convocatorias vigientes
              </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
              <Link
                  className={classes.link}
                  variant="button"
                  color="textPrimary"
                  href="#infocontratacion"
              >Información contractual
              </Link>
              </MenuItem>
            {  <MenuItem onClick={handleClose}>
              <Link
                  className={classes.link}
                  variant="button"
                  color="textPrimary"
                  href="#Terminosdereferencia"
              >Términos de referencia
              </Link>
              </MenuItem>}

              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  variant="button"
                  color="textPrimary"
                  target="_blank"
                  href= {"contratacion/Política_tratamiento_de_datos_UT_Innovanex.pdf"}
                >Politica de tratamiento de datos 
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
              <Link
                  className={classes.link}
                  variant="button"
                  color="textPrimary"
                  target="_blank"
                  href={"contratacion/Manual_de_Contratación_V4_FinalAprobad.pdf"}
              >Manual de contratación
              </Link>
              </MenuItem>
            </Menu>
              {/* <Link
                variant="button"
                color="textPrimary"
                href="https://innovanex.s3.us-east-2.amazonaws.com/DATOS_SERVER/Anexo+N%C2%BA+1+Manual+de+Contrataci%C3%B3n_V4+_FinalAprobad+(2).pdf"
                target="_blank"
                className={classes.link}
              >
                Contratación
              </Link> */}
              <Link
                variant="button"
                color="textPrimary"
                href="#about"
                className={classes.link}
              >
                Sobre nosotros
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                href="#objectives"
                className={classes.link}
              >
                Componentes
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                href="#allies"
                className={classes.link}
              >
                Aliados
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                href="#sites"
                className={classes.link}
              >
                Beneficiados
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                href="#contact"
                className={classes.link}
              >
                Contacto
              </Link>
            </nav>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {/* {renderMenu} */}
    </div>
  );
}
